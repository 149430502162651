<script setup>
import TheCriteria from "@/components/Admin/TheCriteria";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {ref, onMounted, computed} from "vue";
import axios from "axios";
import {criteriaList} from "@/use/criteria-list";

//----------------------------------------------------------------------

const store = useStore()
const route = useRoute()
const results = ref([])
const resultsCount = ref(0)

// имена членов жюри для таблицы
const users = ref([])
// окно с описанием критерия
const isModalInfo = ref(false)
const isModalRating = ref(false)
const currentUserIdForChange = ref(0)

// номер критерия в окне
const sectionIndex = ref(0)
const criteriaIndex = ref(0)

const isLoading = ref(false)
const criteriaSum = ref([0,0,0,0,0,0,0,0])
const criteriaAverage = ref([0,0,0,0,0,0,0,0])

// рейтинг команды
const rating = ref(0)
const criteria = ref([])

//----------------------------------------------------------------------
onMounted( ()=>{
  try {
    init()
  } catch (err) {
    alert ('APPLICATION ERROR')
  }
})

// eslint-disable-next-line no-undef
const props = defineProps({
  teamId:{
    type: String,
    required: true,
  },
  userId:{
    type: String,
    required: true,
  }
})

//----------------------------------------------------------------------

const init = async () => {
  let criteriaCount = 8
  isLoading.value = true
  criteria.value = criteriaList()
  //console.log(props.userId)
  const urlResults = process.env.VUE_APP_ADMIN_URL + "/api/results.php"
  const urlUsers = process.env.VUE_APP_ADMIN_URL + "/api/users.php"

  await axios
      .get(`${urlUsers}?token=${token.value}`)
      .then(response => (users.value = response.data))
      .then(
          await axios
              .get(`${urlResults}?teamId=${props.teamId}&token=${token.value}`)
              .then(response => (results.value = response.data))
      )
      .catch(error => alert(error));

  resultsCount.value = results.value.length

  if (resultsCount.value === 0) {
    setTimeout(() => isLoading.value = false, 3000);
  } else {

    criteriaSum.value = [0,0,0,0,0,0,0,0]
    criteriaAverage.value = [0,0,0,0,0,0,0,0]

    // сумма баллов
    results.value.forEach((item) => {
      for (let i = 0; i < criteriaCount; i++) {
        let number = 'criteria' + (i+1)
        criteriaSum.value[i] += +item[number]
      }
    });
    isLoading.value = false
    criteriaAverage.value = criteriaSum.value.map(item => item/resultsCount.value);

    // рейтинг команды
    // сумма массива criteriaAverage / 9 (критериев)

    rating.value = criteriaAverage.value.reduce(function(a, b){ return a + b }, 0) / criteriaCount;
    rating.value = +rating.value.toFixed(2)
  }
}

// имя члена жюри по id ------------------------------------------------
const getUser = (id) => {
  let user = users.value.find(item => item.id == id);
  return user.name;
}

// оценил результаты пользователь?
const testUser = (id) => {
  return results.value.findIndex(item => item.userId == id)
}

const showModal = (x, y) => {
  criteriaIndex.value = x
  sectionIndex.value = y
  isModalInfo.value = true
}

/*
const editRating = (id) => {
  currentUserIdForChange.value = id
  isModalRating.value = true
}
*/

const updateRating = () => {
  isModalRating.value = false
  init()
  //location.reload();
}

//----------------------------------------------------------------------
const token = computed( () => {return store.getters['authModule/token']})
//const user = computed( () => {return store.getters['authModule/user']})

</script>
<template>
  <div>
    <h3 class="text-center">Результаты оценки команды</h3>

    <div class="card card-outline card-info mt-4">
        <div class="table-responsive mt-2 p-2">
          <table
              class="table table-hover"
          >
            <thead>

            <tr>
              <th style="width: 26%" class="border-0"></th>
              <th style="width: 11%" class="border-0"></th>
              <th class="border-0 text-center" colspan="9">Критерии оценок</th>
            </tr>

            <tr>
              <th style="width: 26%" class="border-0">Кто оценил</th>
              <th style="width: 11%" class="border-0 text-center">Дата</th>
              <th style="width: 7%" class="border-0 text-center">
                <div class="criteria-info" @click="showModal(0, 0)">1</div>
              </th>
              <th style="width: 7%" class="border-0 text-center">
                <div class="criteria-info" @click="showModal(0, 1)">2</div>
              </th>
              <th style="width: 7%" class="border-0 text-center">
                <div class="criteria-info" @click="showModal(1, 0)">3</div>
              </th>
              <th style="width: 7%" class="border-0 text-center">
                <div class="criteria-info" @click="showModal(1, 1)">4</div>
              </th>
              <th style="width: 7%" class="border-0 text-center">
                <div class="criteria-info" @click="showModal(1, 2)">5</div>
              </th>
              <th style="width: 7%" class="border-0 text-center">
                <div class="criteria-info" @click="showModal(2, 0)">6</div>
              </th>
              <th style="width: 7%" class="border-0 text-center">
                <div class="criteria-info" @click="showModal(2, 1)">7</div>
              </th>
              <th style="width: 7%" class="border-0 text-center">
                <div class="criteria-info" @click="showModal(3, 0)">8</div>
              </th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item,index) in users" :key="index">

              <td class="border">
                  {{ getUser(item['id']) }}
              </td>

              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['created'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria1'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria2'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria3'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria4'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria5'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria6'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria7'] }}
                </div>
              </td>
              <td class="border text-center">
                <div v-if="testUser(item['id']) > -1">
                  {{ results[testUser(item['id'])]['criteria8'] }}
                </div>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
        <h3 class="text-center m-4">Рейтинг команды: {{rating}}</h3>
    </div>
  </div>

  <teleport to="body">
    <v-modal
        v-if="isModalInfo"
        :title="criteria[criteriaIndex]['title']"
        @close="isModalInfo = false"
    >
      <div class="modal-body">
        <p class="mt-3">{{ criteria[criteriaIndex]['sections'][sectionIndex]['name'] }}</p>
      </div>
    </v-modal>

    <v-modal
        v-if="isModalRating"
        title="Оценки команды"
        modalType = "modal-dialog-scrollable modal-lg"
        @close="updateRating()"
    >
      <div class="modal-body">
        <the-criteria
            :teamId = "route.params.id"
            :userId = "currentUserIdForChange"
            :key = "currentUserIdForChange"
        >
        </the-criteria>
      </div>
    </v-modal>

  </teleport>

</template>
<style scoped>

.criteria-info {
  display: flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  width:50px;
  height:50px;
  border:3px solid #80b5d3;
  border-radius:50%;
  margin: 0 auto 10px auto;
  color: #80b5d3;
  font-size: 22px;
  cursor: pointer;
}

.criteria-info:hover {
  color:#fff;
  background-color: #80b5d3;
}

</style>