<script setup>
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
const store = useStore()
// запрос на role делаем каждый раз, не только при авторизации
const userData = ref([])

onMounted( ()=>{
  try {
    init()
  } catch (err) {
    alert ('APPLICATION ERROR')
  }
})

const init = async () => {
  const urlData =  process.env.VUE_APP_ADMIN_URL + "/api/userData.php"

  // userId.value получен при авторизации
  await axios
      .get(`${urlData}?token=${token.value}&id=${userId.value}`)
      .then(response => (userData.value = response.data))

  // обновить данные о пользователе
  store.commit('userModule/setUserData', userData.value)
  store.commit('menuModule/initMenu')
}

//----------------------------------------------------------------------
const token = computed( () => {return store.getters['authModule/token']})
const userId = computed( () => {return store.getters['authModule/userId']})

</script>
<template>
  <main class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col"><h3>О мероприятии</h3></div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">

            <div class="card card-outline card-info">
              <div class="card-header pt-3">
                <h4>ЛЕТС ХАК — образовательный интенсив и хакатон «Как участвовать и побеждать в хакатонах»</h4>
              </div>
              <div class="card-body">
                <p>Хочешь начать свой путь в хакатонах?!
                  <br>Хакатоны.рус запускает ЛЕТС ХАК — первый в России образовательный интенсив
                  и хакатон для начинающих хакатонщиков!</p>

                <p><b>ЛЕТС ХАК пройдет в два этапа:</b></p>
                <p>1. Теоретическую часть — образовательный интенсив — серия онлайн уроков от IT-специалистов, организаторов хакатонов, участников и победителей, которые расскажут о всех тонкостях участия и рецептах успеха в IT-соревнованиях.</p>
                <p>2. Практическая часть — продуктовый хакатон — вы сможете отточить полученные знания и побороться за призовой фонд.</p>

                <p><b>Стратегические партнеры:</b></p>
                <p>1. MTS StartUp Hub — платформа для развития технологических проектов в прибыльный бизнес.</p>
                <p>2. Verum — это торговая площадка для сельхозпроизводителей нового поколения.</p>

                <p>Проект реализуется при поддержке Росмолодежь.Гранты.</p>
              </div>
            </div>

            <div class="card card-outline card-danger">
              <div class="card-header pt-3">
                <h4>Партнеры</h4>
              </div>

              <div class="card-body">

                <div class="row">
                  <div class="col-xl-6 col-sm-12 text-center">
                  <img
                      src="https://xn--80ajqb5afw.xn--80aa3anexr8c.xn--p1acf/storage/images/logo/logo_partner_3331486390_3843287867.png"
                      class="logo m-3 img-fluid"
                  >
                  </div>
                  <div class="col-xl-6 col-sm-12 text-center">
                    <img
                        src="https://xn--80ajqb5afw.xn--80aa3anexr8c.xn--p1acf/storage/images/logo/logo_partner_2734332286_3843287867.png"
                        class="logo m-3 img-fluid"
                    >
                  </div>
                  <div class="col-xl-6 col-sm-12 text-center">
                    <img
                        src="https://xn--80ajqb5afw.xn--80aa3anexr8c.xn--p1acf/storage/images/logo/logo_partner_1563854070_3843287867.png"
                        class="logo m-3 img-fluid"
                    >
                  </div>
                  <div class="col-xl-6 col-sm-12 text-center">
                    <img
                        src="https://xn--80ajqb5afw.xn--80aa3anexr8c.xn--p1acf/storage/images/logo/logo_partner_3903662843_3843287867.png"
                        class="logo m-3 img-fluid"
                    >
                  </div>
                  <div class="col-xl-6 col-sm-12 text-center">
                    <img
                        src="https://xn--80ajqb5afw.xn--80aa3anexr8c.xn--p1acf/storage/images/logo/logo_partner_1072448270_3843287867.png"
                        class="logo m-3 img-fluid"
                    >
                  </div>
                  <div class="col-xl-6 col-sm-12 text-center">
                    <img
                        src="https://xn--80ajqb5afw.xn--80aa3anexr8c.xn--p1acf/storage/images/logo/logo_partner_1564132247_3843287867.png"
                        class="logo m-3 img-fluid"
                    >
                  </div>
                  <div class="col-xl-6 col-sm-12 text-center">
                    <img
                        src="https://xn--80ajqb5afw.xn--80aa3anexr8c.xn--p1acf/storage/images/logo/logo_partner_2263125616_3843287867.png"
                        class="logo m-3 img-fluid"
                    >
                  </div>
                  <div class="col-xl-6 col-sm-12 text-center">
                    <img
                        src="https://xn--80ajqb5afw.xn--80aa3anexr8c.xn--p1acf/storage/images/logo/logo_partner_3043311437_3843287867.png"
                        class="logo m-3 img-fluid"
                    >
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<style scoped>
.logo{
  max-width: 350px;
  max-height: 200px;
  -webkit-filter: saturate(0%);
  filter: saturate(0%);
}
</style>